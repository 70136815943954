exports.components = {
  "component---src-layouts-blog-js": () => import("./../../../src/layouts/blog.js" /* webpackChunkName: "component---src-layouts-blog-js" */),
  "component---src-layouts-blog-single-js": () => import("./../../../src/layouts/blog-single.js" /* webpackChunkName: "component---src-layouts-blog-single-js" */),
  "component---src-layouts-contact-js": () => import("./../../../src/layouts/contact.js" /* webpackChunkName: "component---src-layouts-contact-js" */),
  "component---src-layouts-event-js": () => import("./../../../src/layouts/event.js" /* webpackChunkName: "component---src-layouts-event-js" */),
  "component---src-layouts-event-list-js": () => import("./../../../src/layouts/event-list.js" /* webpackChunkName: "component---src-layouts-event-list-js" */),
  "component---src-layouts-homepage-js": () => import("./../../../src/layouts/homepage.js" /* webpackChunkName: "component---src-layouts-homepage-js" */),
  "component---src-layouts-mix-la-js": () => import("./../../../src/layouts/mix-la.js" /* webpackChunkName: "component---src-layouts-mix-la-js" */),
  "component---src-layouts-mix-la-list-js": () => import("./../../../src/layouts/mix-la-list.js" /* webpackChunkName: "component---src-layouts-mix-la-list-js" */),
  "component---src-layouts-page-js": () => import("./../../../src/layouts/page.js" /* webpackChunkName: "component---src-layouts-page-js" */),
  "component---src-layouts-press-list-js": () => import("./../../../src/layouts/press-list.js" /* webpackChunkName: "component---src-layouts-press-list-js" */),
  "component---src-layouts-tag-js": () => import("./../../../src/layouts/tag.js" /* webpackChunkName: "component---src-layouts-tag-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

